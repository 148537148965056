import React from 'react'
import styled from 'styled-components'
import { SiApplepodcasts, SiSpotify, SiGooglepodcasts } from 'react-icons/si'
import { FaPlus } from 'react-icons/fa'

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px;
  gap: 40px;
  background-color: #ffc900;
  background-color: ${({ transparentBg }) =>
    transparentBg ? 'transparent' : '#ffc900'};
  font-family: 'Oswald', sans-serif;

  h2 {
    font-size: 2.5rem;
  }

  a {
    text-decoration: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex-wrap: wrap;
  }
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  max-height: 100px;
  line-height: 1.3;
  text-align: center !important;
  box-sizing: border-box;
  text-decoration: none;
  background: #000;
  padding: 32px;
  color: #fff;

  &:hover {
    text-decoration: underline;
  }
`

const platforms = [
  {
    name: 'Apple',
    url: 'https://podcasts.apple.com/podcast/id1635998876',
    icon: <SiApplepodcasts size={50} color="#fff" />,
  },
  {
    name: 'Google',
    url: 'https://podcasts.google.com/feed/aHR0cHM6Ly9wb2RjYXN0cy5oZWxsb2F1ZGlvLmZtL3BvZGNhc3QvODIwYTUyZTgtYzhiNS00Y2I2LWEwMmEtZjFkMmQ2OTA5YTczL1VrSkc0d0hqY3c',
    icon: <SiGooglepodcasts size={50} color="#fff" />,
  },
  {
    name: 'Spotify',
    url: 'https://open.spotify.com/show/5GiaxRx0jdJaxm7i4IhrhE',
    icon: <SiSpotify size={50} color="#fff" />,
  },
  {
    name: 'More',
    url: 'https://pod.link/1635998876',
    icon: <FaPlus size={50} color="#fff" />,
  },
]

export const PlatformLinks = ({ transparentBg = false, copy }) => {
  return (
    <Content transparentBg={transparentBg}>
      <h2>{copy || 'SUBSCRIBE TO THE PODCAST'} &#x2192; </h2>
      {platforms.map((platform) => {
        const { url, name, icon } = platform
        return (
          <a href={url}>
            <IconWrapper>
              <div>{icon}</div>
              <div>{name}</div>
            </IconWrapper>
          </a>
        )
      })}
    </Content>
  )
}

export default PlatformLinks
