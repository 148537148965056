import React from 'react'
import styled from 'styled-components'
import FluidImage from '../../components/FluidImage'
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share'

import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'

import Layout from '../../components/Layout'
import SEO from '../../components/Seo'
import bgTealBluePinkPath from '../../images/background-teal-blue-pink.png'
import { Box } from '../../components/Shared'
import WysawigWrapper from './wysawigWrapper'
import PlatformLinks from './platformLinks'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${bgTealBluePinkPath});
  background-size: cover;
  padding: 40px;
  padding-top: 200px;
  font-family: 'Oswald', sans-serif;

  h2:first-of-type {
    margin-bottom: 8px;
    text-align: center;
  }

  h2 {
    font-size: 2.5rem;
    font-weight: 400;
    max-width: 700px;
  }

  iframe {
    max-width: 90vw;
  }
`

const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const EmbeddedPlayer = styled.div`
  margin: 80px auto;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    transform: scale(1.5);
  }
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  cursor: pointer;
  text-decoration: none;

  border: none;
  text-transform: 'uppercase';

  text-align: center;

  padding: 24px 32px;

  margin: 40px 40px;

  letter-spacing: 0.08rem;
  font-weight: 600;
  font-size: 0.9rem;

  transition: all 0.3s ease;

  background: #000;
  color: #fff;

  text-decoration: none;
  text-transform: uppercase;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    white-space: nowrap;
  }
`

const ShowNotes = styled.div`
  h2 {
    text-align: left !important;
  }
  li {
    margin-left: 40px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  margin-bottom: 80px;
`

const Podcast = ({ pageContext }) => {
  const {
    podcast: { pageBuilder, uri, seo },
  } = pageContext

  const {
    embeddedPlayer,
    episodeCover,
    episodeNumber,
    episodeSummary,
    guestName,
    heading,
    shownotes,
    transcript,
  } = pageBuilder.layouts[0]

  const [showTranscript, setShowTranscript] = React.useState(false)

  const shareUrl = `www.emancopyco.com${uri}`
  const episodeTitle = `Episode #${episodeNumber || 0}`

  const metaDesc = seo?.metaDesc

  return (
    <Layout>
      <SEO
        title={episodeTitle}
        description={metaDesc || ''}
        path={uri}
        image={episodeCover}
      />
      <Wrapper>
        <Content maxWidth="800px">
          <h2>{episodeTitle}</h2>
          <h2 dangerouslySetInnerHTML={{ __html: heading }} />
          <Box display="flex" margin="80px 0">
            <FacebookShareButton url={shareUrl}>
              <FacebookIcon size={40} />
            </FacebookShareButton>
            <TwitterShareButton
              url={shareUrl}
              title={episodeTitle}
              via={'emancopyco'}
            >
              <TwitterIcon size={40} />
            </TwitterShareButton>
            <LinkedinShareButton url={shareUrl}>
              <LinkedinIcon size={40} />
            </LinkedinShareButton>
            <WhatsappShareButton url={shareUrl} title={episodeTitle}>
              <WhatsappIcon size={40} />
            </WhatsappShareButton>
          </Box>
          <FluidImage
            image={episodeCover}
            alt={
              episodeCover?.altText ||
              `Episode cover for mistakes that made me with ${guestName}`
            }
            width="100%"
            style={{ marginBottom: '40px' }}
          />
          <WysawigWrapper
            dangerouslySetInnerHTML={{ __html: episodeSummary }}
          />
          <EmbeddedPlayer
            dangerouslySetInnerHTML={{ __html: embeddedPlayer }}
          />
          <PlatformLinks transparentBg copy="LISTEN TO THE EPISODE" />
          <ShowNotes>
            <h2>Show notes</h2>
            <WysawigWrapper dangerouslySetInnerHTML={{ __html: shownotes }} />
          </ShowNotes>

          <Button
            onClick={() => {
              setShowTranscript((prev) => !prev)
            }}
          >
            {showTranscript ? (
              <AiFillCaretUp size={35} />
            ) : (
              <AiFillCaretDown size={35} />
            )}
            {showTranscript ? 'Hide' : 'Show'} Transcript
          </Button>
          {showTranscript && (
            <WysawigWrapper dangerouslySetInnerHTML={{ __html: transcript }} />
          )}
        </Content>
      </Wrapper>
    </Layout>
  )
}
export default Podcast
