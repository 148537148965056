import styled from 'styled-components'

export const WysawigWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 800px;

  *:not(.inline-gatsby-image-wrapper) {
    max-width: 800px;
  }

  span[class^=' wp-image'] {
    display: block;
    height: 500px;
  }

  iframe {
    height: 500px;
    width: 50vw;
  }

  p,
  li {
    line-height: 2;
    letter-spacing: 0.06rem;
    margin: 0.75rem 0;
  }

  li {
    counter-increment: item;
    margin-left: 2rem;
  }

  li:before {
    margin-right: 10px;
    content: counter(item);
    background: #ffc900;
    border-radius: 100%;
    color: white;
    width: 1.9em;
    text-align: center;
    display: inline-block;
  }

  ol,
  ul {
    margin: 1.5rem 0;
    list-style: none;
    counter-reset: item;
  }

  em,
  strong {
    font-weight: 600;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 2rem 0;
  }

  h6 {
    font-size: 1rem;
  }

  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.5rem;
  }

  svg,
  a {
    max-height: 10rem;
  }

  .inline-gatsby-image-wrapper {
    display: block;
    margin: 0 auto;
  }
`

export default WysawigWrapper
